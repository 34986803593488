var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("v-card-subtitle", { staticClass: "font-italic px-0" }, [
        _vm._v(
          "\n    NOWW Platform maintains a spam threshold of " +
            _vm._s(_vm.spam.percentThreshold * _vm.spam.maxScore || 2) +
            " or lower to assist with inbox delivery. MMS stats have shown that a spam threshold greater than " +
            _vm._s(_vm.spam.percentThreshold * _vm.spam.maxScore || 2) +
            " may cause deliverability issues.\n  "
        )
      ]),
      _vm.spam && _vm.spam.score !== null && !_vm.spam.loading
        ? _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "div",
                { staticClass: "d-flex justify-center" },
                [
                  _c("v-rating", {
                    attrs: {
                      length: _vm.spam.maxScore,
                      "empty-icon": "mdi-shield-outline",
                      "full-icon": "mdi-shield-alert",
                      "half-icon": "mdi-shield-half-full",
                      "half-increments": "",
                      readonly: "",
                      dense: "",
                      color: _vm.spamRatingColor,
                      "background-color": _vm.spamRatingBackgroundColor,
                      size: "50"
                    },
                    model: {
                      value: _vm.spam.scoreRounded,
                      callback: function($$v) {
                        _vm.$set(_vm.spam, "scoreRounded", $$v)
                      },
                      expression: "spam.scoreRounded"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "d-flex justify-center" }, [
                _c("span", { staticClass: "text-h6 font-weight-bold" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.spam.score) +
                      "/" +
                      _vm._s(_vm.spam.maxScore) +
                      "\n      "
                  )
                ])
              ]),
              _c(
                "v-list",
                { attrs: { "two-line": "" } },
                [
                  _vm._l(_vm.displayItems, function(item, index) {
                    return [
                      item.divider
                        ? _c("v-divider", { key: index, attrs: { inset: "" } })
                        : _c(
                            "v-list-item",
                            { key: index, staticClass: "px-0" },
                            [
                              _c(
                                "v-list-item-avatar",
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: _vm.spamItemRatingColor(
                                          item.score
                                        ),
                                        "x-large": ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.spamItemRatingIcon(item.score)
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text--primary font-weight-bold"
                                      },
                                      [_vm._v(_vm._s(item.score))]
                                    )
                                  ]),
                                  _c(
                                    "v-list-item-subtitle",
                                    {
                                      staticStyle: { "white-space": "initial" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "text--primary" },
                                        [_vm._v(_vm._s(item.code) + " - ")]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "grey--text font-italic"
                                        },
                                        [_vm._v(_vm._s(item.description))]
                                      )
                                    ]
                                  ),
                                  item.hint !== null && item.hint !== ""
                                    ? _c(
                                        "v-list-item-subtitle",
                                        {
                                          staticStyle: {
                                            "white-space": "initial"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "text--primary" },
                                            [
                                              _vm._v("Hint: "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "font-weight-bold"
                                                },
                                                [_vm._v(_vm._s(item.hint))]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        : _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c("v-skeleton-loader", {
                attrs: { type: "card-heading, list-item-avatar@3" }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
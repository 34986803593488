






















































































export default {
  name: 'SpamScore',

  props: {
    spam: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({

  }),

  computed: {
    displayItems () {
      return this.spam && this.spam.items.length > 0 ? [...this.spam.items].map((e, i) => i < this.spam.items.length - 1 ? [e, { divider: true }] : [e]).reduce((a, b) => a.concat(b)) : []
    },
    spamRatingColor () {
      if (!this.spam) return 'grey'
      if (!this.spam.isValid) return 'error'
      return 'warning'
    },
    spamRatingBackgroundColor () {
      if (!this.spam || this.spam.score >= 0.25) return 'grey'
      return 'success'
    }
  },

  methods: {
    spamItemRatingColor (val) {
      if (val <= 0) return 'info'
      if (val < 1) return 'warning'
      return 'error'
    },
    spamItemRatingIcon (val) {
      if (val <= 0) return 'mdi-message'
      if (val < 1) return 'mdi-alert'
      return 'mdi-shield-alert'
    }
  }
}
